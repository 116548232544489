<template>
  <section id="VideoBoards" class="north">
    <ExperiencesCarousel class="desktop" :carouselImages="carouselImages" />
    <ExperiencesMobile class="mobile" />
  </section>
</template>

<script>
  import ExperiencesCarousel from '@/components/global/ExperiencesCarousel'
  import ExperiencesMobile from '@/components/sections/Experiences/ExperiencesMobile'
  
  export default {
    name: "VideoBoards",
    components: {
      ExperiencesCarousel,
      ExperiencesMobile
    },
    data() {
      return {
        carouselImages: [
          {
            image: 'Experiences/Branding/north-video-board-2.jpg',
            title: 'North Video Boards',
            description: 'The north side of Allegiant Stadium features 4 impactful boards that are ideal to create immersive experiences.  Included on the North Side are the Northeast and Northwest Video Boards measuring at 48’H x 121’L along with the Northeast / Northwest Auxiliary Boards at 17’H x 32’L.'
          },
          {
            image: 'Experiences/Branding/north-video-board-1.jpg',
            title: 'North Video Boards',
            description: 'The north side of Allegiant Stadium features 4 impactful boards that are ideal to create immersive experiences.  Included on the North Side are the Northeast and Northwest Video Boards measuring at 48’H x 121’L along with the Northeast / Northwest Auxiliary Boards at 17’H x 32’L.'
          },
          {
            image: 'Experiences/Branding/north-video-board-3.jpg',
            title: 'North Video Boards',
            description: 'The north side of Allegiant Stadium features 4 impactful boards that are ideal to create immersive experiences.  Included on the North Side are the Northeast and Northwest Video Boards measuring at 48’H x 121’L along with the Northeast / Northwest Auxiliary Boards at 17’H x 32’L.'
          },
          {
            image: 'Experiences/Branding/north-video-board-4.jpg',
            title: 'North Video Boards',
            description: 'The north side of Allegiant Stadium features 4 impactful boards that are ideal to create immersive experiences.  Included on the North Side are the Northeast and Northwest Video Boards measuring at 48’H x 121’L along with the Northeast / Northwest Auxiliary Boards at 17’H x 32’L.'
          },
          {
            image: 'Experiences/Branding/north-video-board-5.jpg',
            title: 'North Video Boards',
            description: 'The north side of Allegiant Stadium features 4 impactful boards that are ideal to create immersive experiences.  Included on the North Side are the Northeast and Northwest Video Boards measuring at 48’H x 121’L along with the Northeast / Northwest Auxiliary Boards at 17’H x 32’L.'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  #VideoBoards {
    .desktop {
      @media (max-width: $lg) {
        display: none;
      }
    }
    .mobile {
      position: relative;
      @media (min-width: 993px) {
        display: none;
      }
    }
  }
</style>